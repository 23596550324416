<template>
  <v-card id="invoice-list">
    <v-card-text class="d-flex">
      <v-row class="mt-1">
        <div class="ml-4 d-flex flex-column">
          <span class="mb-4">Bonificação</span>

          <div
            v-if="itemsTable.length > 0"
            class="d-flex items-center mb-2"
          >
            <v-icon
              size="20"
              class="mr-2"
            >
              {{ icons.mdiCalendarAccountOutline }}
            </v-icon>
            <span>REFERÊNCIA: {{ computedFormatDateReference }}</span>
          </div>

          <div
            v-if="itemsTable.length > 0"
            class="d-flex items-center mb-2"
          >
            <v-icon
              size="20"
              class="mr-2"
            >
              {{ icons.mdiFlagVariantOutline }}
            </v-icon>
            <span>SAFRA: {{ bodyFilter.newReference }}</span>
          </div>
        </div>

        <v-spacer />

        <v-text-field
          v-model="filterData"
          class="mr-2"
          label="Buscar por nome"
          outlined
          dense
          @keyup.enter="findFilterResult()"
        />

        <v-btn
          class="mr-4 mb-4"
          :color="itemsTable.length > 0 ? 'success' : 'gray'"
          outlined
          @click="modalFilter = true"
        >
          <v-icon size="">
            {{ icons.mdiFilterVariant }}
          </v-icon>

          <span class="ml-2">Filtro</span>
        </v-btn>

        <v-btn
          v-show="itemsTable.length > 0"
          class="mr-4 mb-4"
          color="error"
          outlined
          @click="clearGrid"
        >
          <v-icon size="">
            {{ icons.mdiFilterVariantRemove }}
          </v-icon>

          <span class="ml-2">Limpar</span>
        </v-btn>

        <v-menu
          offset-y
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              v-show="itemsTable.length > 0"
              class="ml-2"
              color="warning"
              outlined
              v-bind="attrs"
              v-on="on"
            >
              <span class="ml-2">Exportar</span>
              <v-img
                class="ml-2"
                :src="require('@/assets/download-icon.svg')"
              />
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="item in itemsListExport"
              :key="item"
              link
            >
              <v-list-item-title
                @click="exportCsv(item)"
                v-text="item"
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="listOfFilteredItems"
      :loading="loadingTable"
      loading-text="Carregando dados..."
      class="text-no-wrap"
    >
      <template #[`item.name`]="{ item }">
        <div class="container-vacation">
          {{ item.name }}

          <div
            v-show="item.vacation"
            class="vacation"
          >
            Férias
          </div>
        </div>
      </template>

      <template #[`item.cpf`]="{ item }">
        {{ item.cpf ? formattedCpfNumber(item.cpf) : '' }}
      </template>

      <template #[`item.parcel_2`]="{ item }">
        {{
          item.parcel_2
            ? Number(item.parcel_2).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
            : 'R$ 0,00'
        }}
      </template>

      <template #[`item.adiplencia`]="{ item }">
        {{ item.adiplencia }} %
      </template>

      <template #[`item.parcel_5`]="{ item }">
        {{
          item.parcel_5
            ? Number(item.parcel_5).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
            : 'R$ 0,00'
        }}
      </template>

      <template #[`item.total`]="{ item }">
        {{
          item.total ? Number(item.total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'
        }}
      </template>

      <template #[`item.delivery`]="{ item }">
        {{
          item.delivery
            ? Number(item.delivery).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
            : 'R$ 0,00'
        }}
      </template>

      <template #[`item.action`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click="detailsItems(item)"
            >
              <v-icon size="22">
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Detalhar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <div class="no-data-container">
          <span>Nenhum  registro encontrado!</span>
          <p>Por favor, verifique os filtros de pesquisa.</p>

          <v-img
            width="150"
            :src="require(`@/assets/no-data-image.svg`)"
          ></v-img>
        </div>
      </template>
    </v-data-table>

    <v-dialog
      v-model="openModal"
      max-width="1600px"
    >
      <commission-quote-details
        :key="(referenceToDetails, vendorCpfToDetails)"
        :reference="referenceToDetails"
        :cpf-vendor="vendorCpfToDetails"
        :vendor-name="nameVendor"
        :average="average"
        @close="openModal = false"
      ></commission-quote-details>
    </v-dialog>

    <v-dialog
      v-model="modalFilter"
      max-width="600px"
    >
      <BonificationsFilter
        @close="modalFilter = false"
        @filteredData="$event => setTableData($event)"
        @filteredBody="$event => setBodyFilter($event)"
      />
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      color="error"
    >
      POR FAVOR, selecione um filtro para realizar a exportação!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="#fff"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import {
  mdiCalendarAccountOutline,
  mdiCheckDecagram,
  mdiCircleOffOutline,
  mdiDownload,
  mdiEyeOutline,
  mdiFilterVariant,
  mdiFilterVariantRemove,
  mdiFlagVariantOutline,
  mdiMagnify,
  mdiSendCircleOutline,
  mdiTrendingUp,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import moment from 'moment'
import 'moment/locale/pt-br'
import BonificationsFilter from './BonificationsFilter.vue'
import CommissionQuoteDetails from './CommissionQuoteDetails.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    CommissionQuoteDetails,
    BonificationsFilter,
  },

  mixins: [formatters],

  data() {
    return {
      average: {},
      filterData: '',
      modalFilter: false,
      nameVendor: '',
      noDataText: '',
      itemSearch: '',
      itemSearchDate: '',
      classNoDataText: '',
      referenceToDetails: '',
      vendorCpfToDetails: '',
      listRegionsItems: ['IMPERATRIZ', 'AÇAILÂNDIA', 'GRAJAÚ'],

      icons: {
        mdiFlagVariantOutline,
        mdiCalendarAccountOutline,
        mdiTrendingUp,
        mdiEyeOutline,
        mdiMagnify,
        mdiCheckDecagram,
        mdiSendCircleOutline,
        mdiDownload,
        mdiCircleOffOutline,
        mdiFilterVariant,
        mdiFilterVariantRemove,
      },

      snackbar: false,
      dateMenu: false,
      openModal: false,
      loadingTable: false,
      loaderExport: false,

      headers: [
        {
          text: 'FILIAL',
          value: 'region',
          sortable: false,
          align: '',
        },
        {
          text: 'LOJA',
          value: 'fantasy_name',
          sortable: false,
          align: '',
        },
        {
          text: 'FUNCIONÁRIO',
          value: 'name',
          sortable: false,
          align: '',
        },
        {
          text: 'CPF',
          value: 'cpf',
          sortable: false,
          align: '',
        },
        {
          text: 'META ATUAL',
          value: 'goal',
          sortable: false,
          align: 'center',
        },
        {
          text: 'REALIZADO',
          value: 'sales',
          sortable: false,
          align: 'center',
        },
        {
          text: 'ADIMPLÊNCIA',
          value: 'adiplencia',
          sortable: false,
          align: 'center',
        },
        {
          text: '2° PARCELA',
          value: 'parcel_2',
          sortable: false,
          align: 'center',
        },
        {
          text: '5° PARCELA',
          value: 'parcel_5',
          sortable: false,
          align: 'center',
        },
        {
          text: 'BÔNUS ENTREGA',
          value: 'delivery',
          sortable: false,
          align: 'center',
        },

        // {
        //   text: 'SAFRA',
        //   value: 'reference',
        //   sortable: false,
        //   align: 'center',
        // },
        {
          text: 'TOTAL',
          value: 'total',
          sortable: false,
          align: 'center',
        },
        {
          text: 'AÇÕES',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      itemsListExport: ['Sintético', 'Analítico'],
      itemsTableDetails: [],
      listOfFilteredItems: [],
      bodyFilter: {},
      reference: '',
    }
  },

  computed: {
    computedItemSearchDate() {
      return this.formatDate(this.itemSearchDate)
    },

    computedFormatDateReference() {
      const newReference = moment(this.bodyFilter.reference, 'YYYY-MM').format('MMM/YYYY').toUpperCase()

      return newReference
    },
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.noDataText = 'SELECIONE UM FILTRO'
    this.classNoDataText = 'colorWarning'
  },

  methods: {
    clearGrid() {
      this.listOfFilteredItems = []
      this.itemsTable = []
    },

    setTableData(data) {
      this.listOfFilteredItems = data
      this.itemsTable = data
    },

    setBodyFilter(body) {
      this.bodyFilter = body
    },

    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(({ name }) => {
        const employee = name.toLowerCase()

        return employee.includes(filterDataLower)
      })
    },

    async getDataCommission() {
      this.loadingTable = true
      await axiosIns.post('/api/v1/integration/contract/second_fourth_installment_listing').then(res => {
        this.itemsTable = res.data.data
        this.loadingTable = false
      })
    },

    async getDataSalesInputSelected() {
      this.loadingTable = true
      this.noDataText = 'NENHUM REGISTRO ENCONTRADO'
      this.classNoDataText = ''
      this.itemsTable = []
      const body = {
        region: this.itemSearch,
        reference: this.itemSearchDate,
      }

      await axiosIns.post('/api/v1/integration/contract/second_fourth_installment_listing', body).then(res => {
        this.itemsTable = res.data.data
        this.loadingTable = false
      })
    },

    async clearFilter() {
      this.noDataText = 'SELECIONE UM FILTRO'
      this.classNoDataText = 'colorWarning'
      this.itemSearch = ''
      this.itemSearchDate = ''

      this.itemsTable = []
    },

    async exportCsv(item) {
      const file = await this.getCsv(item)
      if (file) {
        saveAs(file)
      }
    },

    async getCsv(item) {
      this.loaderExport = true

      // if (this.itemSearch === '' && this.itemSearchDate === '') {
      //   this.snackbar = true
      //   this.loaderExport = false

      //   return null
      // }

      if (item === 'Sintético') {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('sheet')

        worksheet.columns = [
          { header: 'LOJA', key: 'region' },
          { header: 'FUNCIONÁRIO', key: 'name' },
          { header: 'FILIAL', key: 'fantasy_name' },
          { header: 'META ATUAL', key: 'goal' },
          { header: 'REALIZADO', key: 'sales' },
          { header: 'ADIMPLÊNCIA', key: 'adiplencia' },
          { header: '2° PARCELA', key: 'parcel_2' },
          { header: '5° PARCELA', key: 'parcel_5' },
          { header: 'BÔNUS ENTREGA', key: 'delivery' },
          { header: 'CPF', key: 'cpf' },
          { header: 'REFERÊNCIA', key: 'reference' },
          { header: 'TOTAL', key: 'total' },
          { header: 'MÉDIA', key: 'media.total' },
        ]

        console.log(this.listOfFilteredItems)

        this.itemsTable.map(itemTable => {
          worksheet.addRow([
            itemTable.region,
            itemTable.name,
            itemTable.fantasy_name,
            Number(itemTable.goal),
            Number(itemTable.sales),
            Number(itemTable.adiplencia),
            Number(itemTable.parcel_2),
            Number(itemTable.parcel_5),
            Number(itemTable.delivery),
            itemTable.cpf,
            itemTable.reference,
            Number(itemTable.total),
            itemTable.media ? 'Sim' : '',
          ])
        })

        let blob = null
        await workbook.xlsx.writeBuffer().then(data => {
          blob = new File([data], 'dados_bonificação', {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        })

        this.loaderExport = false

        return blob
      }
      if (item === 'Analítico') {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('sheet')

        const body = {
          region: this.bodyFilter.region,
          reference: this.bodyFilter.reference,
          filial: this.bodyFilter.filial,
          vacation: this.bodyFilter.vacation,
        }

        await axiosIns.post('/api/v1/integration/contract/second_fourth_installment_controlling', body).then(res => {
          this.itemsTableDetails = res.data
        })

        worksheet.columns = [
          { header: 'VENDEDOR', key: 'name' },
          { header: 'CLIENTE', key: 'client_name' },
          { header: 'FILIAL', key: 'fantasy_name' },
          { header: 'PROPOSTA/DIGITO', key: 'proposal_number' },
          { header: 'STATUS PROPOSTA', key: 'contract_status' },
          { header: 'QTD. PARC. PAG. CLIENTE', key: 'parcel_number_paid' },
          { header: 'RECEBIDO 2° PARCELA', key: 'commission_value2' },
          { header: 'RECEBIDO 5° PARCELA', key: 'commission_value5' },
          { header: 'BÔNUS 2° PARCELA', key: 'parcel_2' },
          { header: 'BÔNUS 5° PARCELA', key: 'parcel_5' },
          { header: 'TOTAL BÔNUS', key: 'total' },
        ]

        this.itemsTableDetails.map(itemTable => {
          worksheet.addRow([
            itemTable.name,
            itemTable.client_name,
            itemTable.fantasy_name,
            `${itemTable.proposal_number} ${itemTable.proposal_digit}`,
            itemTable.contract_status,
            itemTable.parcel_number_paid,
            Number(itemTable.commission_value2),
            Number(itemTable.commission_value5),
            Number(itemTable.parcel_2),
            Number(itemTable.parcel_5),
            Number(itemTable.total),
          ])
        })

        let blob = null
        await workbook.xlsx.writeBuffer().then(data => {
          blob = new File([data], 'dados_bonificação', {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        })

        this.loaderExport = false

        return blob
      }

      return ''
    },

    detailsItems(item) {
      this.openModal = true
      this.referenceToDetails = item.reference
      this.vendorCpfToDetails = item.cpf
      this.nameVendor = item.name
      this.average = item.media
    },

    /**
     * formatted name for avatar icon
     *
     * @param {string} name
     * @returns the first two letters of the name
     */
    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')
      const first = nameArray[0]
      const last = nameArray[nameArray.length - 1]

      const newName = [first, last]

      return newName.map(word => word.charAt(0).toUpperCase()).join('')
    },

    /**
     * Brazilian standard date format
     *
     * @param {string} date
     * @returns formatted date
     */
    formatDate(date) {
      if (!date) return null

      const [year, month] = date.split('-')

      return `${month}/${year}`
    },
  },
}
</script>
<style scoped>
.colorWarning {
  color: #ff9800 !important;
}

.datePicker {
  width: 100%;
}

.no-data-container{
  padding: 100px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-data-container span{
  font-size: 18px;
}

.container-vacation{
  display: flex;
  align-items: center;
}

.vacation{
  width: 60px;
  height: 30px;
  background: #16B1FF1A;
  border-radius: 50px;
  color: #17b1ff;
  font-weight: bold;
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
